<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import router from '@/router'
import {formatCurrency, formatNumber } from '@/utils/formatter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/vee-validate'
import Swal from "sweetalert2";

import { ref, onMounted } from '@vue/composition-api'

export default {
  page: {
    title: "Update Capacity",
    meta: [{ name: "description", content: appConfig.description }]
  },
  props:{
    id_schedule: String,
  },
  components: { 
    Layout, PageHeader, ValidationProvider, ValidationObserver
  },
  data() {
    return {
      title: "Update Capacity",
      items: [],
      select_label: "Select",
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true,
      value_flightstatus: null
    };
  },
  setup(props){

    const { $put } = useHttp()
    const { $get } = useHttp()

    // const scheduleId = ref('')
    const selectedSchedule = ref({})
    const masterRef = ref(null)

    const form = ref({
      capacity : '',
      scheduleId : props.id_schedule
      })
    
    const getSchedule = async () => {
      const {data} = await $get({ url: 'master/schedule_v2/' + form.value.scheduleId})
      selectedSchedule.value = {...data, sch_departure_datetime : data.sch_departure_date + ' ' + data.sch_departure_time}
      // selectedSchedule.sch_availablecapacity  = formatNumber(data.value)
     
    }

    const validationForm = async () => {
      
       if(validationDetail()){
        const validated = await masterRef.value.validate()
        if(validated){

    //    let dataSubmit = {
    //     schId : form.value.schId,
    //     schCapacity : form.value.capacity
    //  }

        $put({
        url: 'master/schedule_capacity',
        data: form.value
      })
          masterRef.value.reset();
          router.push({ name: 'appsmd-schedule-list' })
        }
     }
    }

     const validationDetail = ()=> {
     if(
       form.value <1 ||
       form.value === null
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "There is no details found",
          html: `You must add details`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }

    onMounted(() => {
     getSchedule()
    })



    return {
      // scheduleId,
      validationForm,
      form,
      selectedSchedule,
      formatCurrency,
      formatNumber,
      masterRef,
      required
    }


  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
               <validation-observer ref="masterRef">
                <b-form @submit.prevent>
              <div class="col-12">
                  <div class="form-group mb-3">
                        <label>Available</label><br />
                        <b-form-input id="" name="" value="" v-model="selectedSchedule.sch_availablecapacity" disabled></b-form-input>
                      </div>
                  <div class="form-group mb-3">
                        <label>Flight No</label><br />
                        <b-form-input id="" name="" value="" v-model="selectedSchedule.sch_flight_no" disabled></b-form-input>
                      </div>
                  <div class="form-group mb-3">
                        <label>Departure</label><br />
                        <b-form-input id="" name="" value="" v-model="selectedSchedule.sch_departure_datetime" disabled></b-form-input>
                      </div>
                       <div class="form-group mb-3">
                        <label>Capacity</label><br />
                        <b-form-input id="" name="" value="" v-model="selectedSchedule.sch_capacity" disabled></b-form-input>
                      </div>
                  <div class="form-group mb-3">
                        <label>Capacity Update</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="capacity"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.capacity"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
              </div>
              </b-form>
              </validation-observer>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-schedule-detail'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>